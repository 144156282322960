import React, { Component } from "react"
import { Link } from "gatsby"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Scrollspy from "react-scrollspy"
import styled from "styled-components"
import logo from "../../../images/logo.svg"
import Fade from 'react-reveal/Fade';

import {
  Nav,
  NavItem,
  Brand,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
} from "./style"

const NAV_ITEMS = [
  {name: "Features", link:"#features", isAnchor: true}, 
  {name: "Sign in", link:"https://app.betterplan.com.au", isAnchor: false, showMobile: true}
]

export default class Navigation extends Component {


  state = {
    mobileMenuOpen: false,
    hasScrolled: false,
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  handleScroll = event => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 32) {
      this.setState({ hasScrolled: true })
    } else {
      this.setState({ hasScrolled: false })
    }
  }

  toggleMobileMenu = () => {
    this.setState(prevState => ({ mobileMenuOpen: !prevState.mobileMenuOpen }))
  }

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false })
    }
  }

  getNavAnchorLink = item => {

    return item.isAnchor ?
    <AnchorLink href={item.link} onClick={this.closeMobileMenu}>
      {item.name}
    </AnchorLink>:
    <a href={item.link} onClick={this.closeMobileMenu}>
      {item.name}
    </a>
  }

  getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      <Scrollspy
        items={NAV_ITEMS}
        currentClassName="active"
        mobile={mobile}
        offset={-64}
      >
        {NAV_ITEMS.filter(n => !mobile || n.showMobile).map(navItem => (
          <NavItem key={navItem}>{this.getNavAnchorLink(navItem)}</NavItem>
        ))}
      </Scrollspy>
    </NavListWrapper>
  )

  render() {
    const { mobileMenuOpen } = this.state

    return (
      <Nav {...this.props} scrolled={this.state.hasScrolled}>
        <SlidingContainer>
          <Brand scrolled={this.state.hasScrolled}>
              <Link href="/">
                <Fade>
                  <img src={logo} alt="Logo" />
                </Fade>
              </Link>
          </Brand>

          <Mobile hide>{this.getNavList({mobile: true})}</Mobile>
        </SlidingContainer>
      </Nav>
    )
  }
}

const SlidingContainer = styled(StyledContainer)`

@keyframes slideInFromTop {
  0% {
    transform: translateY(-200%);
  }
  100% {
    transform: translateY(0);
  }
}

animation: 1s ease-out 0s 1 slideInFromTop;`

