import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Container } from "../global"

const Banner = ({image, children}) => {
  return (
    <BannerWrapper id="top">
      <Container>
        <Flex>
          <BannerTextGroup>
            {children}
          </BannerTextGroup>
          {image && <ImageWrapper>
            <StyledImage fluid={image} />
            <br />
          </ImageWrapper>}
        </Flex>
      </Container>
    </BannerWrapper>
  )
}

export default Banner


export const BannerWrapper = styled.header`
  padding: 160px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${props => props.theme.screen.md}) {
  }
`
export const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 16px;
`

export const BannerTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    text-align: center;
  }

  h1 {
    margin: 0 0 24px;
  }

  h2 {
    margin-bottom: 24px;
    ${props => props.theme.font_size.regular}
  }

  p {
    font-size: 20px;
    margin-bottom: 48px;
  }
`

export const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`
const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`

export const StyledImage = styled(Img)`
  width: 500px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 300px;
    display: none;
  }
`
